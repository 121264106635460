.about-yaleg {
    text-align: center;
    margin: 0 auto;
    
  }
  
  .about-header {
    margin: 40px 20px 40px 20px;
    min-height: 180px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #6D48FF;
  }
  
  .about-logo {
    width: 150px;
  }
  
  .about-section {
    padding: 0px 40px;
    margin: 0 auto;
    text-align: right; /* RTL Layout */
    display: flex;
    justify-content: end;
    
  }
  .about-content{
    width: 85%;
  }
  .about-section h2 {
    font-size: 1.8em;
    color: #6D48FF;
  }
  
  .about-section p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .about-section ul li {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .hr-image {
    width: 100px;
    margin-top: 20px;
  }
  
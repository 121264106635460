.contact-section {
    background: linear-gradient(135deg, #6D48FF 0%, #6D48FF 100%);
    padding: 50px 20px;
    text-align: center;
    color: #fff;
  }
  
  .contact-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-content h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .contact-info {
    margin-bottom: 30px;

  }
  
  .contact-info p {
    font-size: 1.1em;
    margin: 5px 0;
    color: #d6d6d6;
  }
  
  .social-media-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .social-media-icons a {
    color: #fff;
    font-size: 1.5em;
    transition: color 0.3s;
  }
  
  .social-media-icons a:hover {
    color: #f0f0f0;
  }
  
  .terms-link {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-size: 1.1em;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .terms-link:hover {
    background-color: #fff;
    color: #6D48FF;
  }
  
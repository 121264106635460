.custom-faq-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(0deg, #6D48FF 0%, #6D48FF 100%);
    padding: 50px 20px;
    padding-top: 190px;
    position: relative;
}
  
.custom-faq-content {
    flex: 1;
    color: #fff;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
}
  
.custom-faq-content h2 {
    font-size: 2em;
    margin-bottom: 10px;
}
  
.custom-underline {
    width: 100px;
    height: 4px;
    background-color: #fff;
    margin: 0 auto 30px;
}
  
.custom-accordion {
    max-width: 600px;
    margin: 0 auto;
    text-align: right; /* Align text to the right for Arabic */
}
  
.custom-accordion-item {
    margin-bottom: 10px;
}
  
.custom-accordion-button {
    width: 100%;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    border-radius: 5px;
    text-align: right;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.custom-accordion-button.active {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid #d9d8d8; /* Square border around the button when active */
}
  
.custom-accordion-icon {
    margin-left: 10px;
}
  
.custom-accordion-content {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}
.custom-accordion-content p {
    color: #fff;
}
  
.custom-faq-image {
    flex: 1;
    max-width: 500px;
}
  
.custom-faq-image img {
    width: 100%;
    height: auto;
    
}
  
.custom-wave-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: translateY(-1px); /* To hide a tiny gap */
}
  
.custom-wave-top svg {
    display: block;
    width: 100%;
    height: auto;
}
  
@media (max-width: 768px) {
    .custom-faq-content {
        width: 100%;
    }
    .custom-faq-section {
        flex-direction: column;
        text-align: center;
    }
  
    .custom-faq-image {
        display: none;
    }
}

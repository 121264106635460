/* Header.css */

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #6D48FF 0%, #6D48FF 100%);
  padding: 50px;
  position: relative;
  color: white;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 85%);
  direction: rtl;
  overflow: visible; /* Allow content to overflow the header */
  height: 640px;
}

.app-info {
  max-width: 50%;
  text-align: right;
}

.app-info h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.app-info p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: white;
}

.install-button {
  background-color: white;
  color: #6D48FF;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.install-button:hover {
  background-color: #f0f0f0;
}

.app-mockup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 50%; /* Match the width of .app-info */
}

.app-mockup img {
  position: absolute;
  
}

.app-mockup .img1 { bottom: -250px; max-width: 240px; left: 120px; z-index: 2; /* transform: scaleX(1.1); */ }

.app-mockup .img3 { bottom: -210px; max-width: 180px; left: 70px; z-index: 1; }

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    text-align: center;
  }

  .app-info {
    max-width: 100%;
    text-align: center;
  }

  .app-mockup img {
    order: 0;
    margin-top: 20px;
    max-width: 165px;
  }

  .app-mockup .img1 {
    max-width: 150px;
    bottom: 0;
  }

  .app-mockup .img3 {
    max-width: 110px;
    bottom: 20px;
    
  }
}
@media (max-width: 625px) {
  .app-mockup .img1 {
    left: 50px;
  }

  .app-mockup .img3 {
   
    left: 30px;
  }
}
@media (max-width: 475px) {
  .app-mockup .img1 {
    max-width: 130px;
  }

  .app-mockup .img3 {
    max-width: 100px;
   
  }
}
@media (max-width: 353px) {
  .app-mockup .img1 {
    left: 30px;
    max-width: 120px;
}

  .app-mockup .img3 {
    left: 10px;
    max-width: 90px;
   
  }
}

  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .app-header {
      flex-direction: column; /* Stack the elements vertically */
      text-align: center; /* Center align the text */
    }
  
    .app-info {
      max-width: 100%;
      text-align: center; /* Center align text on smaller screens */
    }
  
    .app-mockup img {
      order: 0; /* Reset the order so that the image appears below the text */
      margin-top: 20px; /* Add some space between the text and the image */
      max-width: 165px;
    }
  }

/* FeaturesSection.css */
.features-section {
    background-color: #f0f0f0; /* Light gray background */
    padding: 20px;
    padding-top: 100px;
    position: relative;
    overflow: hidden;
  }
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px; /* Adjust as needed */
    
  }
  
  .centered-content img {
    margin: 10px 0; /* Add some spacing around the image */
  }
  
  .features-image {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 550px;
    width: 100%;
    height: auto;
    
  }
  
  .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
   min-height: 700px;
  }
  
  .feature-item {
    text-align: center;
    margin: 20px;
    position: absolute; /* Position absolute for precise placement */
    width: 25%;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    border: 2px solid #4776e6;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1rem;
    color: #333;
  }
  
  /* Positioning for the items to form a parallelogram shape */
  .item-top-left {
    top: 0%;
    left: 2%;
  }
  
  .item-top-right {
    top: 0%;
    right: 2%;
  }
  
  .item-bottom-left {
    bottom: 0%;
    left: 2%;
  }
  
  .item-bottom-right {
    bottom: 0%;
    right: 2%;
  }
  
  /* Medium and smaller screens: Stack items vertically */
  @media (max-width:1120px){
    .features-image {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 450px;
      width: 100%;
      height: auto;
  }   
  }
  @media (max-width: 1023px) {
    .features-image{
        display: none;
    }
    .features-section {
      padding: 100px 20px; /* Adjust padding */
    }
  
    .features-container {
      flex-direction: column;
      align-items: center;
      flex-wrap:nowrap;
    }
  
    .feature-item {
      position: static; /* Remove absolute positioning */
      margin: 20px 0;
    }
  
    .features-section::before {
      display: none; /* Hide background image on smaller screens */
    }
    .feature-item {
      width: 75%;
    }
  }



  /* Add these animations to your CSS */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.app-mockup img {
  animation: fadeInUp 2s ease-in-out;
}

.app-mockup .img1 {
  animation-delay: 1s;
}

.app-mockup .img3 {
  animation-delay: 1s;
}

.feature-item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.feature-item.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Staggered animations for each feature item */
.item-top-left {
  transition-delay: 0.5s;
}

.item-top-right {
  transition-delay: 1s;
}

.item-bottom-left {
  transition-delay: 1.5s;
}

.item-bottom-right {
  transition-delay: 2s;
}

.client-rate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

}

.client-rate {
  text-align: center;
  background-color: #f0f0f0; ;
  border-radius: 12px;
  border: 2px solid #6D48FF;
  padding: 20px;
  width: 300px; /* Adjust the width as needed */
}

.rate-icon {
  background-color: #6D48FF;
  color: #ffffff;
  border-radius: 50%;
  width: 60px; /* Adjust the size as needed */
  height: 60px; /* Adjust the size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 24px; /* Adjust the icon size as needed */
}

.client-rate h3 {
  margin: 10px 0;
  font-size: 18px; /* Adjust the font size as needed */
}

.client-rate p {
  font-size: 16px; /* Adjust the font size as needed */
  margin: 0;
}

.franchise-form-section {
    padding: 20px 60px 20px 60px; 
    margin: auto;
    direction: rtl;
    max-width: 90%;
  }
  

  
  .section-header {
    font-size: 24px;
    color: #6D48FF;
    margin-bottom: 15px;
  }
  
  input, select, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-section {
    text-align: center;
  }
  
  .submit-button {
    background-color: #6D48FF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #5437cc;
  }

  .form-check .form-check-input {
    float: right !important;
  
    margin-left:auto !important;
}
  
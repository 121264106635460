.add-strength-section {
    background-color: #6D48FF;
    padding: 50px 0;
    text-align: center;
    color: white;
    border-radius: 20px;
  }
  
  .content-wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .add-strength-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .add-strength-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .buttons-container  .download-button {
    background-color: white;
    color: #6D48FF;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    
  }
  
  .know-more-button {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
  }
  
  .buttons-container .download-button:hover, .know-more-button:hover {
    opacity: 0.8;
  }
 
  
.why-yaleg-section {
  background-color: #f0f0f0;
  padding: 50px 0;
  color: #fff;
  display: flex;
  gap: 20px;
  position: relative; /* Ensure this container's relative position allows sticky to work */
}

.why-yaleg-section .right-col {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.why-yaleg-section .left-col {
  flex: 1;
  color: #6D48FF;
  text-align: end;
  display: flex;
  justify-content: end;
  align-items: end;
  position: sticky;
  top: 150px; /* Sticky position starts when the left column hits 50px from the top of the viewport */
  align-self: flex-start; /* Make sure it's aligned at the start */
  
}

.why-yaleg-section .advantage-item {
  align-items: flex-end;
  text-align: end;
  margin-bottom: 20px;
}

.why-yaleg-section .try-app-button {
  background-color: #6D48FF;
  color: #fff;
  padding: 10px 60px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  margin-right: 0px;
}

.why-yaleg-section .try-app-button:hover {
  background-color: #5834c8;
}

@media (max-width: 1000px) {
  .why-yaleg-section{
      display: flex;
      flex-direction: column-reverse;
  }
  .why-yaleg-section .left-col {
  
    position:static;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
}

.competitive-advantages-section {
    background-color: #6D48FF;
    padding: 50px 0;
    color: #fff;
    display: flex;
  }
  
  .left-col {
    width: 50%;
    text-align:center;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
  }
  
  .icon-circle-white {
    background-color: #fff;
    color: #6D48FF;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 20px;
  }

  .icon-circle {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc2f36e;
    color: #6D48FF;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 20px;
}
  
  .section-title {
    font-size: 18px;
    margin: 0 0 20px 0;
  }
  
  .section-subtitle {
    font-size: 22px;
    margin-bottom: 30px;
    font-weight: 500;
    color: #fff;
  }
  
  .try-app-button {
    background-color: #fff;
    color: #6D48FF;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    text-decoration: none;
  }
  
  .right-col {
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .advantage-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    text-align: center;
    padding: 20px;
    color: #6D48FF;
  }
  
  .advantage-item h3 {
    margin-top: 15px;
  }
  
  .advantage-item p {
    margin-top: 10px;
    font-size: 14px;
  }

  @media (max-width: 1112px) {
    .competitive-advantages-section {
        display: flex;
        flex-direction: column-reverse;
    }
    .right-col{
        width: 100%;
        margin-top: 20px;

    }
    .left-col{
        width: 100%;
        
    }
}

@media (max-width: 750px) {

  .right-col{
    grid-template-columns: 1fr;

  }}
  
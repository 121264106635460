.available-areas-section {
    background-color: #f0f0f0;
    padding: 50px 20px;
    text-align: center;
    color: #333;
    position: relative;
  }
  
  .content-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }
  
  .available-areas-section h2 {
    font-size: 2.5rem;
    color: #6D48FF; /* Accent color for the header */
    margin-bottom: 20px;
  }
  
  .available-areas-section p {
    font-size: 1.1em;
    margin-bottom: 30px;
    color: #555;
  }
  
  .map-image {
    max-width: 70%;
    height: auto;
    
  }
  

  
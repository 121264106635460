.about-franchise-section{
    padding: 40px;
   
    display: flex;
    justify-content: end;
}

.about-franchise-content{
    width: 85%;
    line-height: 1.8rem;
}
.about-franchise-content h2 {
    font-size: 1.8em;
    color: #6D48FF;
    margin-bottom: 20px;
  }

  .about-franchise-content .try-app-button {
    
    background-color: #6D48FF;
    color: #fff;
    padding: 10px 60px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    margin-right: 30px;
  }
.about-steps-section {
    display: flex;
    flex-direction: column;
    align-items: end;
    position: relative;
    padding-right: 80px;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: right;
    margin-bottom: 50px;
    position: relative;
  }
  
  .step-content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse; /* Ensures the icon is on the right for RTL */
    position: relative;
  }
  
  .step-icon {
    width: 60px;
    height: 60px;
    background-color: #eee;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-left: 20px; /* Adjust to add space on the left instead of right */
  }
  
  .step-text {
    max-width: 400px;
  }
  
  .step-header {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .step-description {
    font-size: 14px;
    max-width: 400px;
  }
  
  .step::before {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #ccc;
    top: 80px; /* Adjust this based on the step's position */
    right: 30px; /* Adjust this for RTL, positioning the line on the right */
    transform: translateX(50%);
    height: 50px; /* Line height */
    z-index: -1;
  }
  
  .step:last-child::before {
    display: none; /* Hide the last line */
  }
  
  .active .step::before {
    background-color: #6D48FF; /* Color change when active */
  }
.franchise-apply-btn-container{
    display: flex;
    justify-content: end;
    padding-right: 80px;
    padding-bottom: 80px;
}
  .franchise-apply-btn{
    background-color: #6D48FF;
    color: #fff;
    padding: 13px 30px;
    border-radius: 20px;
    text-decoration: none;
   
  }
  .franchise-apply-btn:hover{
    background-color: #5834c8;
  }
  
  /* You can add responsive design if needed */
  @media (max-width: 768px) {
    .step-content {
      flex-direction: column;
      text-align: center;
    }
  
    .step-icon {
      margin-bottom: 15px;
      margin-left: 0;
    }
  
    .step-text {
      text-align: center;
    }
  }
  
/* Header Styling */
.terms-header {
    margin: 0;
    width: 100%;
    overflow-x: hidden;
    box-shadow: 0 3px 6px rgb(0 0 0 / 29%);
    background: linear-gradient(135deg, #cfd2d5 0%, #f1f4fd 100%);
    color: #6D48FF;
    position: relative;
    z-index: 1000;
    direction: rtl;
}

.terms-header .second-row {
    padding-top:50px;
    text-align: right;
}

.first-row {
    display: flex;
    align-items: center;
    background: transparent;
    transition: background-color 0.3s ease;
}

.first-row.sticky {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    color: #6D48FF !important;
    z-index: 1000;
}

.first-row .logo {
    max-width: 120px;
}

.navigation a {
    color: #6D48FF;
    text-decoration: none;
    margin-left: 20px;
    
    
}
.first-row.sticky .navigation a{
    color: #6D48FF;
}
.navigation a.active {
    padding: 5px;
    border-bottom: 2px solid #6D48FF;
}
.first-row.sticky .navigation a.active{
    padding: 5px;
    border-bottom: 2px solid #6D48FF;
   
}

.second-row{
    height: 200px;
} 
.second-row h1 {
    font-size: 2rem;
    margin: 0;
}



/* Terms Content Styling */
.terms-content {
    padding: 40px 20px;
    direction: rtl;
    color: #333;
}

/* Media Query for Sticky Header */
@media (max-width: 768px) {
    .terms-header .row {
        flex-direction: column;
        text-align: center;
    }

    .second-row .navigation {
        display: block;
        margin-top: 10px;
    }
}
.terms-footer{
    background-color: #222;
    color: rgba(88, 94, 104, 0.8);
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.1rem;
}

.terms-footer a {
    color: #757887;
    text-decoration: none;
}
.terms-footer a:hover {
    color: #fff;
}


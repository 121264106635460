.header-navbar {
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgb(0 0 0 / 29%);
    background: linear-gradient(135deg, #ccc2f34d 0%, #f1f4fd 100%);
    padding: 10px 15px;
    width: 100%;
    top: 0;
    z-index: 1000;
    direction: rtl;
  }
  
  .navbar-logo img {
    width: 90px;
  }
  
  .navbar-links {
    list-style-type: none;
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
  }
  
  .navbar-links li {
    display: inline;
    cursor: pointer;
    color: #6D48FF;
  }
  
  .navbar-links a,.navbar-links button {
    color: #6D48FF;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 500;
    border: none;
  }
  
  .navbar-links a:hover, li:hover{
    color: #5834c8; /* Add hover color change */
  }
  
  .mobile-menu-icon {
    display: none;
    font-size: 1.8rem;
    cursor: pointer;
    color: #6D48FF;
  }
  
  @media (max-width: 1050px) {
    .navbar-links {
      position: absolute;
      top: 100px;
      right: 0;
      background-color: white;
      width: 100%;
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      display: none;
      z-index: 1000;
    }
  
    .navbar-links.active {
      display: flex;
    }
  
    .mobile-menu-icon {
      display: block;
      margin-left: 0;
    }
  
    .header-navbar {
      justify-content: space-between;
    }
  }
  
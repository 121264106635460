/* Home Component Styles */
.download-app-section {
    padding: 60px 20px;
    background-color: #f0f0f0;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .download-app-section-img {
    width: 50%;
  }
  .download-app-section-img img{
    max-width: 600px;
    max-height: 500px;
  }
  
  .download-app-info {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .download-app-info h2 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .download-hr-image {
    width: 120px;
    height: 5px;
    margin: 20px auto;
  }
  
  .download-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .download-button {
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    transition: background-color 0.3s, border-color 0.3s;
  }
  .downloadPlatform{
    font-weight: bold;
  }
  
  .download-button i {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .download-button:hover {
    background-color: #333;
    border-color: #333;
  }
  
  .download-button span {
    display: block;
    text-align: right;
  }
  @media (max-width: 1112px) {
    .download-app-section img {
        max-width: 500px;
    }}
    @media (max-width: 998px) {
        .download-app-section {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }
        .download-app-section img {
            margin-top: 50px;
        }
      
    
    }

    @media (max-width: 500px) {
   
      
      .download-app-section img {
        max-width: 400px;
    }
    .download-buttons {
      display: flex;
      flex-direction: column;
      gap: 20px;
  }
  }